import FileDownloader from "../utilities/FileDownloader";
import Api from "../api/Api";

const extensions = {
    def: 'txt',
    srt: 'srt',
    log: 'txt',
    scc: 'scc',
    dfxp: 'dfxp',
    vtt: 'vtt',
    sbv: 'sbv'
}

export default class SubtitleManager {
    static async download({media, format, preset}) {
        const data = await SubtitleManager.get({media, format, preset});
        const extension = extensions[format] || extensions.def;

        if (window.logSubtitle === true) {
            console.log(data);
            return;
        }

        const file = new File([data], `${media.name}.${extension}`, {
            type: "text/plain"
        });

        FileDownloader.local(file);
    }

    static async get({media, format, preset}) {
        return Api.getSubtitle(media.ulid, format, preset);
    }
}
