import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {ArrowLeftOnRectangleIcon, Cog6ToothIcon, UserCircleIcon} from "@heroicons/react/24/solid";
import {Popover, Transition} from "@headlessui/react";
import {Link} from "react-router-dom";

export default function UserMenu() {
    const {user, workspace} = useSelector((state) => state.identity);
    const authed = user !== null;

    return (
        <div className="relative">
            <Popover className="relative">
                <Popover.Button className="h-10 w-10 ml-2 rounded-full bg-slate-200 flex justify-center items-center">
                    <UserCircleIcon className="h-6 w-6" />
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute mt-2 right-0">
                        <div className="drop-shadow w-48 mx-4 mt-1 flex flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark">
                            <div className="rounded-t-lg bg-slate-100 text-slate-600 dark:bg-navy-800 dark:text-navy-200 p-2">
                                {authed &&
                                    <div className="text-right mr-2">
                                        <div className="font-semibold text-s">
                                            {workspace.name} workspace
                                        </div>
                                        <div className="text-xs">
                                            {user.email}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="p-2">
                                <ul className="font-medium">
                                    <li>
                                        <Link to="/settings" className="w-full inline-flex items-center space-x-2 transition-colors hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100">
                                            <Cog6ToothIcon className="h-4 w-4" />
                                            <span>Settings</span>
                                        </Link>
                                    </li>
                                    <li className="m-0">
                                        <hr className="my-1"/>
                                    </li>
                                    <li>
                                        <Link to="/auth/signout" className="w-full inline-flex items-center space-x-2 transition-colors hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100">
                                            <ArrowLeftOnRectangleIcon className="h-4 w-4" />
                                            <span>Logout</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div>
    );
}
