import Navigation from "../components/Navigation";
import React from "react";

export default class BaseLayout extends React.Component {
    render() {
        return (
            <>
                <Navigation/>
                {this.props.children}
            </>
        );
    }
}
