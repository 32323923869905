import React from "react";
import {InformationCircleIcon, ShoppingCartIcon} from "@heroicons/react/24/outline";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const hideOnPaths = [
    '/onboarding/plan',
    '/onboarding/subscription/success',
    '/auth/signout',
    '/auth/verify'
]

export default function SubscriptionAlert() {
    const {workspace} = useSelector((state) => state.identity);
    const {pathname} = useLocation();

    if (workspace.has_subscription || hideOnPaths.includes(pathname)) {
        return null;
    }

    return (
        <div
            className="flex items-center justify-between space-x-2 px-[var(--margin-x)] pb-4 pt-5 transition-all duration-[.25s]">
            <div className="flex items-center w-full">
                <div className="alert flex overflow-hidden rounded-lg border border-info text-info w-full">
                    <div className="bg-info p-3 text-white flex items-center">
                        <InformationCircleIcon className="h-8 w-8"/>
                    </div>
                    <div className="px-4 py-3 sm:px-5 flex-1">
                        <h4 className="text-lg">You don't have an active subscription</h4>
                        <p>You need an active subscription to use the service, without that, the functionality will
                            be limited. Click the subscribe button to purchase a subscription.</p>
                    </div>
                    <div className="flex items-center pr-4">
                        <Link to="/onboarding/plan"
                              className="btn bg-gradient-to-r from-sky-400 to-blue-600 font-medium text-white">
                            <ShoppingCartIcon className="h-6 w-6 mr-2"/> Subscribe
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
