import React from "react";
import Head from "./Head";
import SubscriptionAlert from "./SubscriptionAlert";

export default function Page(props) {
    const containerClass = props.containerClass || 'main-content w-full px-[var(--margin-x)] pb-8'
    return (
        <main className={containerClass}>
            {props.title && <Head title={props.title}/>}
            <Head title={props.title}/>

            {props.title && !props.noTitle &&
                <div
                    className="flex items-center justify-between space-x-2 px-[var(--margin-x)] pb-4 pt-5 transition-all duration-[.25s]">
                    <div className="flex items-center space-x-1">
                        <h3 className="text-lg font-medium text-slate-700 line-clamp-1 dark:text-navy-50">
                            {props.title}
                        </h3>
                    </div>
                </div>
            }

            <SubscriptionAlert/>

            {props.children}
        </main>
    );
}
