import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    user: undefined,
    workspace: {},
    plan: {},
    balances: {
        minutes: 0,
        addon_minutes: 0,
        total: 0,
        interval: null
    },

};

const identitySlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIdentity: (state, action) => {
            if (action.payload === null) {
                return {
                    ...initialState,
                    user: null,
                };
            }
            return action.payload;
        },
    },
    extraReducers: builder => {
    },
});

export const {setIdentity} = identitySlice.actions;

export default identitySlice.reducer;
