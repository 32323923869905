import React, {useEffect, useMemo} from "react";
import Page from "../components/Page";
import {useLoaderData, useNavigate} from "react-router-dom";
import MediaManager from "../classes/managers/media";
import CaptionEditor from "../components/caption-editor/CaptionEditor";
import formatBytes from "../classes/utilities/formatBytes";
import languages, {getLanguageById} from "../constants/all-language-codes"
import formatTime from "../classes/utilities/formatTime";
import {toast} from "react-toastify";

export function mediaEditPageLoader({params})  {
    return MediaManager.get(params.id);
}

export default function MediaEditPage() {
    const {media} = useLoaderData();
    const transcription = media.transcriptions[0];
    const language = getLanguageById(media.language);
    const navigate = useNavigate();

    useEffect(() => {
        if (media.status !== 7) {
            toast("You cannot edit this media.", {
                type: 'error'
            });
            navigate("/media");
        }
    }, [media]);

    return (
        <Page title={`Edit ${media.name}`} noTitle>
            <div className="grid grid-cols-12 lg:gap-6">
                <div className="col-span-12 pt-6 lg:col-span-8 lg:pb-6">
                    <div className="card p-4 lg:p-6">
                        <CaptionEditor name={media.name} initialValue={transcription}/>
                    </div>
                </div>
                <div className="col-span-12 py-6 lg:sticky lg:bottom-0 lg:col-span-4 lg:self-start">
                    <p className="border-b border-slate-200 pb-2 text-base text-slate-800 dark:border-navy-600 dark:text-navy-100">
                        Details
                    </p>
                    <div className="mt-5">
                        <p className="text-base leading-tight">
                            {media.name} <br />
                            <span className="text-xs">
                                {media.original_name}<br />
                                {formatBytes(media.size)}
                            </span>
                        </p>
                        <div className="my-4 h-px  bg-slate-200 dark:bg-navy-500" />
                        <p>
                            <strong>Support Id:</strong> {media.ulid}<br />
                            <strong>Language:</strong> {language.label}<br />
                            <strong>Duration:</strong> {formatTime(media.duration)}<br />
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    );
}
