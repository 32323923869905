import React from "react";
import PageLoader from "../components/loaders/PageLoader";

export default Component => {
    const WithSuspense = function () {
        return <Component fallback={<PageLoader/>}/>;
    }

    return WithSuspense;
}
