import axios from 'axios';
import {toast} from "react-toastify";
import config from "../../config";

class Api {

    constructor() {
        this.axios = axios.create({
            withCredentials: true,
            baseURL: config.apiEndpoint,
            timeout: 60000,
            headers: {
                'Accept': 'application/json'
            },
        });
    }

    broadcastAuth(socketId, channelName) {
        return this.axios.post('broadcasting/auth', {
            socket_id: socketId,
            channel_name: channelName
        })
    }

    getCsrfCookie() {
        return this.withErrorHandling(async () => {
            return this.axios.get('csrf');
        });
    }

    login(credentials) {
        return this.withErrorHandling(async () => {
            return this.axios.post('auth/login', credentials);
        });
    }

    logout() {
        return this.withErrorHandling(async () => {
            return this.axios.post('auth/logout');
        });
    }

    requestPasswordReset(email) {
        return this.withErrorHandling(async () => {
            return this.axios.post('auth/reset', {email});
        });
    }

    resetPassword({email, password, password_confirmation, token}) {
        return this.withErrorHandling(async () => {
            return this.axios.put('auth/reset', {email, password, password_confirmation, token});
        });
    }

    createUser(data) {
        return this.withErrorHandling(async () => {
            return this.axios.post('user', data);
        });
    }

    verifyUser(user, hash, expires, signature) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`user/${user}/verify`, {
                params: {
                    expires, hash, signature
                }
            });
        });
    }

    sendEmailVerification(user) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`user/${user}/verify`);
        });
    }

    getIdentity() {
        return this.withErrorHandling(async () => {
            return this.axios.post('identity');
        });
    }

    indexMedia() {
        return this.withErrorHandling(async () => {
            return this.axios.get('media');
        });
    }

    storeMedia(media) {
        return this.withErrorHandling(async () => {
            return this.axios.post('media', media);
        });
    }

    getMedia(id) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`media/${id}`);
        });
    }

    updateTranscriptionResult(ulid, result) {
        return this.withErrorHandling(async () => {
            return this.axios.put(`transcription/${ulid}`, {result});
        });
    }

    destroyMedia(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.delete(`media/${ulid}`);
        });
    }

    getMediaUploadUri(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`media/${ulid}/upload`);
        });
    }

    async uploadMedia(media, file, onProgress) {
        const uploadUri = await this.getMediaUploadUri(media.ulid);
        return axios({
            url: uploadUri,
            method: "put",
            data: file,
            headers: {"Content-Type": file.type},
            maxContentLength: (100 * 1024 * 1024 * 1024),
            timeout: (60 * 60 * 1000), // 60 mins
            onUploadProgress: (progressEvent) => {
                if (typeof onProgress === 'function') {
                    onProgress(progressEvent.loaded / progressEvent.total * 100)
                }
            }
        });
    }

    verifyMedia(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`media/${ulid}/verify`);
        });
    }

    transcribeMedia(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`media/${ulid}/transcribe`);
        });
    }

    indexPlans() {
        return this.withErrorHandling(async () => {
            return this.axios.get('plan');
        });
    }

    storePlan(code, interval) {
        return this.withErrorHandling(async () => {
            return this.axios.post('plan', {code, interval});
        });
    }

    editPlan(code, interval) {
        return this.withErrorHandling(async () => {
            return this.axios.get('plan/edit');
        });
    }

    storePreset(data) {
        return this.withErrorHandling(async () => {
            return this.axios.post('preset', data);
        });
    }

    indexPreset() {
        return this.withErrorHandling(async () => {
            return this.axios.get('preset');
        });
    }

    showPreset(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`preset/${ulid}`);
        });
    }

    updatePreset(ulid, data) {
        return this.withErrorHandling(async () => {
            return this.axios.put(`preset/${ulid}`, data);
        });
    }

    deletePreset(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.delete(`preset/${ulid}`);
        });
    }

    getSubtitle(ulid, format, preset = null) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`media/${ulid}/subtitle`, {
                params: { preset, format },
            });
        });
    }

    // Private
    async withErrorHandling(callback) {
        try {
            const {data, status} = await callback();

            if (typeof data.redirect === 'string') {
                window.location = data.redirect;
            }

            if (status === 204) {
                return true;
            }

            if (data.result === undefined) {
                throw new Error('Server response format is incorrect.');
            }

            return data.result;
        } catch (error) {
            if (error.response) {
                const response = error.response;
                if (response.status === 422) {
                    const data = response.data;
                    error.isValidationError = true;
                    error.fields = data.errors;
                }

                if (response.data) {
                    if (response.data.message) {
                        toast(response.data.message, {
                            type: 'error'
                        });
                    }
                }
            }
            throw error;
        }
    }
}

export default new Api();
