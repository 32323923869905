import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

const redirectToOnboarding = [
    '/dashboard',
    '/media/create',
    '/presets/create',
]

const withAuthorization = Component => {

    const WithAuthorization = function () {
        const {user, workspace} = useSelector((state) => state.identity);
        const {pathname} = useLocation();

        if (user === null) {
            return <Navigate to="/auth/signin" replace={true}/>
        }

        if (user.email_verified_at === null && pathname !== '/auth/verify') {
            return <Navigate to="/auth/verify" replace={true}/>
        }

        if (user.email_verified_at !== null && pathname === '/auth/verify') {
            return <Navigate to="/" replace={true}/>
        }

        if (workspace.has_subscription === false && redirectToOnboarding.includes(pathname)) {
            toast(<div><strong>Welcome to I ♡ captions!</strong><br/>Let's get started by creating a plan.</div>);
            return <Navigate to="/onboarding/plan" replace={true}/>
        }

        return <Component/>;
    }

    return <WithAuthorization/>
}

export default withAuthorization;
