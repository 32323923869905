import {ArrowPathIcon, ChevronDoubleUpIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import Event from "./Event";
import {toast} from "react-toastify";
import TranscriptionManager from "../../classes/managers/transcription";

export default function CaptionEditor(props) {
    const [value, setValue] = useState(null);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setValue(props.initialValue);
    }, []);

    if (value === null) {
        return null;
    }

    const onEventChange = (index, event) => {
        if (busy) {
            return
        }
        const nextValue = {
            ...value,
            result: {
                ...value.result,
                events: [
                    ...value.result.events
                ]
            }
        };
        nextValue.result.events[index] = event;
        setValue(nextValue);
    }

    const onSave = async () => {
        try {
            setBusy(true);
            await TranscriptionManager.update(value);
            toast(`Data saved successfully`, {
                type: 'success'
            });
        } catch (error) {
            console.error(error)
            toast(`Could not save, please try again later.`, {
                type: 'error'
            });
        } finally {
            setBusy(false);
        }

    }

    return (
        <div>
            <div className="flex flex-row">
                <h2 className="flex-1 line-clamp-1 text-lg font-medium tracking-wide text-slate-700 dark:text-navy-100">{props.name}</h2>
                <button
                    className="btn space-x-2 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
                    onClick={onSave}
                    disabled={busy}
                >
                    {busy && <ArrowPathIcon className="w-4 h-4 animate-spin" />}
                    {!busy && <ChevronDoubleUpIcon className="w-4 h-4" />}
                    <span>Save</span>
                </button>
            </div>

            <div className="pt-4">
                {value.result.events.map((event, key) =>
                    <Event key={key} onChange={event => onEventChange(key, event)} event={event} />
                )}
            </div>

        </div>
    )
}
