import React, {useState} from "react";
import formatTime from "../../classes/utilities/formatTime";
import {PencilSquareIcon} from "@heroicons/react/24/outline";

export default function Event(props) {
    const {event} = props;

    const onChange = (changeEvent) => {
        const nextEvent = {...event}
        nextEvent.lines = [
            // Remove line breaks
            changeEvent.target.value.replace(/(\r\n|\n|\r)/gm, "")
        ]
        props.onChange(nextEvent);
    }

    return (
        <div className="mt-4 leading-tight">
            <div className="flex flex-row">
                <div className="text-xs text-sky-600">
                    {formatTime(event.start)} - {formatTime(event.end)}
                </div>
            </div>
            <div className="flex-1 text-base">
                <textarea
                    className="mt-1 border border-solid w-full p-0 resize-none"
                    value={event.lines.join("")}
                    onChange={onChange}
                    rows="2"
                />
            </div>
        </div>
    )
}
