import {EventEmitter} from 'events';
import Api from "./Api";
import {toast} from "react-toastify";
import WebNotifications from "../utilities/web-notifications";
import GtmTracker, {GtmEvents} from "../utilities/gtm-tracker";

class Auth extends EventEmitter {

    constructor() {
        super();
        WebNotifications.on('identity-updated', () => {
            this.onIdentityUpdated()
                .catch(console.error);
        });
    }

    async autoLogin() {
        try {
            await Api.getCsrfCookie();
        } catch (error) {
            toast('Fatal error: could not initiate api communication.', {
                autoClose: false,
                type: "error",
                closeButton: false,
                closeOnClick: false,
                draggable: false
            });
            throw new Error('Fatal error: could not initiate api communication.');
        }

        const identity = await Api.getIdentity();

        if (identity) {
            GtmTracker.setUserId(identity.user.ulid);
            GtmTracker.push(GtmEvents.APP_AUTO_LOGIN);
        }

        this.emit('change', { identity });
    }

    async login(credentials) {
        await Api.login(credentials)
        const identity = await Api.getIdentity();
        GtmTracker.setUserId(identity.user.ulid);
        GtmTracker.push(GtmEvents.APP_LOGIN);
        this.emit('change', { identity });
    }

    async reload() {
        const identity = await Api.getIdentity();
        if (!identity) {
            GtmTracker.setUserId(null);
            GtmTracker.push(GtmEvents.APP_AUTO_LOGOUT);
        }
        this.emit('change', { identity });
    }

    async logout() {
        await Api.logout();
        GtmTracker.setUserId(null);
        GtmTracker.push(GtmEvents.APP_LOGOUT);
        this.emit('change', { identity: null });
    }

    async onIdentityUpdated() {
        await this.reload();
    }
}

export default new Auth();
