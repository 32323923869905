import {Link, useRouteError} from "react-router-dom";
import React from "react";
import Layout from "../layouts/Layout";
import Head from "../components/Head";

export default function () {
    const error = useRouteError();
    return (
        <Layout name="base" useChildren>
            <Head title='Error'/>
            <main className="grid w-full grow grid-cols-1 place-items-center">
                <div className="max-w-md p-6 text-center">
                    <div className="w-full">
                        <img className="w-full" src="/images/illustrations/error-500.svg" alt="image"/>
                    </div>
                    <p className="pt-4 text-7xl font-bold text-primary dark:text-accent">
                        {error.code}
                    </p>

                    <p className="pt-2 text-slate-500 dark:text-navy-200">
                        {error.statusText || error.message}
                    </p>

                    <Link to="/"
                          className="btn mt-8 h-11 bg-primary text-base font-medium text-white hover:bg-primary-focus hover:shadow-lg hover:shadow-primary/50 focus:bg-primary-focus focus:shadow-lg focus:shadow-primary/50 active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:hover:shadow-accent/50 dark:focus:bg-accent-focus dark:focus:shadow-accent/50 dark:active:bg-accent/90"
                    >
                        Back To Home
                    </Link>
                </div>
            </main>
        </Layout>
    )
}
