const dictionary = {};

const languages = [
    {
        code: "af-ZA",
        label: "Afrikaans (South Africa)"
    },
    {
        code: "am-ET",
        label: "Amharic (Ethiopia)"
    },
    {
        code: "ar-AE",
        label: "Arabic (United Arab Emirates)"
    },
    {
        code: "ar-BH",
        label: "Arabic (Bahrain)"
    },
    {
        code: "ar-DZ",
        label: "Arabic (Algeria)"
    },
    {
        code: "ar-EG",
        label: "Arabic (Egypt)"
    },
    {
        code: "ar-IL",
        label: "Arabic (Israel)"
    },
    {
        code: "ar-IQ",
        label: "Arabic (Iraq)"
    },
    {
        code: "ar-JO",
        label: "Arabic (Jordan)"
    },
    {
        code: "ar-KW",
        label: "Arabic (Kuwait)"
    },
    {
        code: "ar-LB",
        label: "Arabic (Lebanon)"
    },
    {
        code: "ar-LY",
        label: "Arabic (Libya)"
    },
    {
        code: "ar-MA",
        label: "Arabic (Morocco)"
    },
    {
        code: "ar-OM",
        label: "Arabic (Oman)"
    },
    {
        code: "ar-PS",
        label: "Arabic (Palestinian Territories)"
    },
    {
        code: "ar-QA",
        label: "Arabic (Qatar)"
    },
    {
        code: "ar-SA",
        label: "Arabic (Saudi Arabia)"
    },
    {
        code: "ar-SY",
        label: "Arabic (Syria)"
    },
    {
        code: "ar-TN",
        label: "Arabic (Tunisia)"
    },
    {
        code: "ar-YE",
        label: "Arabic (Yemen)"
    },
    {
        code: "az-AZ",
        label: "Azerbaijani (Latin, Azerbaijan)"
    },
    {
        code: "bg-BG",
        label: "Bulgarian (Bulgaria)"
    },
    {
        code: "bn-IN",
        label: "Bengali (India)"
    },
    {
        code: "bs-BA",
        label: "Bosnian (Bosnia and Herzegovina)"
    },
    {
        code: "ca-ES",
        label: "Catalan (Spain)"
    },
    {
        code: "cs-CZ",
        label: "Czech (Czechia)"
    },
    {
        code: "cy-GB",
        label: "Welsh (United Kingdom)"
    },
    {
        code: "da-DK",
        label: "Danish (Denmark)"
    },
    {
        code: "de-AT",
        label: "German (Austria)"
    },
    {
        code: "de-CH",
        label: "German (Switzerland)"
    },
    {
        code: "de-DE",
        label: "German (Germany)"
    },
    {
        code: "el-GR",
        label: "Greek (Greece)"
    },
    {
        code: "en-AU",
        label: "English (Australia)"
    },
    {
        code: "en-CA",
        label: "English (Canada)"
    },
    {
        code: "en-GB",
        label: "English (United Kingdom)"
    },
    {
        code: "en-GH",
        label: "English (Ghana)"
    },
    {
        code: "en-HK",
        label: "English (Hong Kong SAR)"
    },
    {
        code: "en-IE",
        label: "English (Ireland)"
    },
    {
        code: "en-IN",
        label: "English (India)"
    },
    {
        code: "en-KE",
        label: "English (Kenya)"
    },
    {
        code: "en-NG",
        label: "English (Nigeria)"
    },
    {
        code: "en-NZ",
        label: "English (New Zealand)"
    },
    {
        code: "en-PH",
        label: "English (Philippines)"
    },
    {
        code: "en-SG",
        label: "English (Singapore)"
    },
    {
        code: "en-TZ",
        label: "English (Tanzania)"
    },
    {
        code: "en-US",
        label: "English (United States)"
    },
    {
        code: "en-ZA",
        label: "English (South Africa)"
    },
    {
        code: "es-AR",
        label: "Spanish (Argentina)"
    },
    {
        code: "es-BO",
        label: "Spanish (Bolivia)"
    },
    {
        code: "es-CL",
        label: "Spanish (Chile)"
    },
    {
        code: "es-CO",
        label: "Spanish (Colombia)"
    },
    {
        code: "es-CR",
        label: "Spanish (Costa Rica)"
    },
    {
        code: "es-CU",
        label: "Spanish (Cuba)"
    },
    {
        code: "es-DO",
        label: "Spanish (Dominican Republic)"
    },
    {
        code: "es-EC",
        label: "Spanish (Ecuador)"
    },
    {
        code: "es-ES",
        label: "Spanish (Spain)"
    },
    {
        code: "es-GQ",
        label: "Spanish (Equatorial Guinea)"
    },
    {
        code: "es-GT",
        label: "Spanish (Guatemala)"
    },
    {
        code: "es-HN",
        label: "Spanish (Honduras)"
    },
    {
        code: "es-MX",
        label: "Spanish (Mexico)"
    },
    {
        code: "es-NI",
        label: "Spanish (Nicaragua)"
    },
    {
        code: "es-PA",
        label: "Spanish (Panama)"
    },
    {
        code: "es-PE",
        label: "Spanish (Peru)"
    },
    {
        code: "es-PR",
        label: "Spanish (Puerto Rico)"
    },
    {
        code: "es-PY",
        label: "Spanish (Paraguay)"
    },
    {
        code: "es-SV",
        label: "Spanish (El Salvador)"
    },
    {
        code: "es-US",
        label: "Spanish (United States)"
    },
    {
        code: "es-UY",
        label: "Spanish (Uruguay)"
    },
    {
        code: "es-VE",
        label: "Spanish (Venezuela)"
    },
    {
        code: "et-EE",
        label: "Estonian (Estonia)"
    },
    {
        code: "eu-ES",
        label: "Basque"
    },
    {
        code: "fa-IR",
        label: "Persian (Iran)"
    },
    {
        code: "fi-FI",
        label: "Finnish (Finland)"
    },
    {
        code: "fil-PH",
        label: "Filipino (Philippines)"
    },
    {
        code: "fr-BE",
        label: "French (Belgium)"
    },
    {
        code: "fr-CA",
        label: "French (Canada)"
    },
    {
        code: "fr-CH",
        label: "French (Switzerland)"
    },
    {
        code: "fr-FR",
        label: "French (France)"
    },
    {
        code: "ga-IE",
        label: "Irish (Ireland)"
    },
    {
        code: "gl-ES",
        label: "Galician"
    },
    {
        code: "gu-IN",
        label: "Gujarati (India)"
    },
    {
        code: "he-IL",
        label: "Hebrew (Israel)"
    },
    {
        code: "hi-IN",
        label: "Hindi (India)"
    },
    {
        code: "hr-HR",
        label: "Croatian (Croatia)"
    },
    {
        code: "hu-HU",
        label: "Hungarian (Hungary)"
    },
    {
        code: "hy-AM",
        label: "Armenian (Armenia)"
    },
    {
        code: "id-ID",
        label: "Indonesian (Indonesia)"
    },
    {
        code: "is-IS",
        label: "Icelandic (Iceland)"
    },
    {
        code: "it-CH",
        label: "Italian (Switzerland)"
    },
    {
        code: "it-IT",
        label: "Italian (Italy)"
    },
    {
        code: "ja-JP",
        label: "Japanese (Japan)"
    },
    {
        code: "jv-ID",
        label: "Javanese (Latin, Indonesia)"
    },
    {
        code: "ka-GE",
        label: "Georgian (Georgia)"
    },
    {
        code: "kk-KZ",
        label: "Kazakh (Kazakhstan)"
    },
    {
        code: "km-KH",
        label: "Khmer (Cambodia)"
    },
    {
        code: "kn-IN",
        label: "Kannada (India)"
    },
    {
        code: "ko-KR",
        label: "Korean (Korea)"
    },
    {
        code: "lo-LA",
        label: "Lao (Laos)"
    },
    {
        code: "lt-LT",
        label: "Lithuanian (Lithuania)"
    },
    {
        code: "lv-LV",
        label: "Latvian (Latvia)"
    },
    {
        code: "mk-MK",
        label: "Macedonian (North Macedonia)"
    },
    {
        code: "ml-IN",
        label: "Malayalam (India)"
    },
    {
        code: "mn-MN",
        label: "Mongolian (Mongolia)"
    },
    {
        code: "mr-IN",
        label: "Marathi (India)"
    },
    {
        code: "ms-MY",
        label: "Malay (Malaysia)"
    },
    {
        code: "mt-MT",
        label: "Maltese (Malta)"
    },
    {
        code: "my-MM",
        label: "Burmese (Myanmar)"
    },
    {
        code: "nb-NO",
        label: "Norwegian Bokmål (Norway)"
    },
    {
        code: "ne-NP",
        label: "Nepali (Nepal)"
    },
    {
        code: "nl-BE",
        label: "Dutch (Belgium)"
    },
    {
        code: "nl-NL",
        label: "Dutch (Netherlands)"
    },
    {
        code: "pl-PL",
        label: "Polish (Poland)"
    },
    {
        code: "ps-AF",
        label: "Pashto (Afghanistan)"
    },
    {
        code: "pt-BR",
        label: "Portuguese (Brazil)"
    },
    {
        code: "pt-PT",
        label: "Portuguese (Portugal)"
    },
    {
        code: "ro-RO",
        label: "Romanian (Romania)"
    },
    {
        code: "ru-RU",
        label: "Russian (Russia)"
    },
    {
        code: "si-LK",
        label: "Sinhala (Sri Lanka)"
    },
    {
        code: "sk-SK",
        label: "Slovak (Slovakia)"
    },
    {
        code: "sl-SI",
        label: "Slovenian (Slovenia)"
    },
    {
        code: "so-SO",
        label: "Somali (Somalia)"
    },
    {
        code: "sq-AL",
        label: "Albanian (Albania)"
    },
    {
        code: "sr-RS",
        label: "Serbian (Cyrillic, Serbia)"
    },
    {
        code: "sv-SE",
        label: "Swedish (Sweden)"
    },
    {
        code: "sw-KE",
        label: "Swahili (Kenya)"
    },
    {
        code: "sw-TZ",
        label: "Swahili (Tanzania)"
    },
    {
        code: "ta-IN",
        label: "Tamil (India)"
    },
    {
        code: "te-IN",
        label: "Telugu (India)"
    },
    {
        code: "th-TH",
        label: "Thai (Thailand)"
    },
    {
        code: "tr-TR",
        label: "Turkish (Turkey)"
    },
    {
        code: "uk-UA",
        label: "Ukrainian (Ukraine)"
    },
    {
        code: "uz-UZ",
        label: "Uzbek (Latin, Uzbekistan)"
    },
    {
        code: "vi-VN",
        label: "Vietnamese (Vietnam)"
    },
    {
        code: "wuu-CN",
        label: "Chinese (Wu, Simplified)"
    },
    {
        code: "yue-CN",
        label: "Chinese (Cantonese, Simplified)"
    },
    {
        code: "zh-CN",
        label: "Chinese (Mandarin, Simplified)"
    },
    {
        code: "zh-CN-sichuan",
        label: "Chinese (Southwestern Mandarin, Simplified)"
    },
    {
        code: "zh-HK",
        label: "Chinese (Cantonese, Traditional)"
    },
    {
        code: "zh-TW",
        label: "Chinese (Taiwanese Mandarin, Traditional)"
    },
    {
        code: "zu-ZA",
        label: "Zulu (South Africa)"
    }
]

const makeDictionary = function () {
    languages.forEach(language =>
        dictionary[language.code] = language
    )
}

export function getLanguageById(id) {
    if (Object.keys(dictionary).length === 0) {
        makeDictionary()
    }
    return dictionary[id] || {}
}

export default languages
