import React from "react";
import {HeartIcon} from "@heroicons/react/24/solid";
import {useSelector} from "react-redux";
import UserMenu from "./UserMenu";
import toTime from "../classes/utilities/toTime";

export default function NavBar() {
    const {user, workspace, balances} = useSelector((state) => state.identity);
    const totalMinutes = 0; //workspace.availablePlanMinutes + workspace.availableAddonMinutes;
    const authed = user !== null;

    return (
        <nav className="header print:hidden">
            <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
                <div className="flex w-full items-center justify-between">
                    <div className="flex-1">
                        <div className="font-sans text-lg font-semibold flex items-center">
                            I <HeartIcon
                            className="h-5 w-5 ml-1 mr-1 text-pink-400 transition-transform duration-500 ease-in-out hover:rotate-[360deg]"/> captions
                            <div
                                className="badge bg-primary/10 text-primary dark:bg-accent-light/15 dark:text-accent-light ml-2"
                            >
                                Beta
                            </div>
                        </div>
                    </div>
                    <div className="h-7 w-7">
                        <button
                            className="menu-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80">
                            <span/><span/><span/>
                        </button>
                    </div>

                    {authed &&
                        <div className="text-right mr-2">
                            <div className="font-semibold text-xs">
                                {workspace.name}
                            </div>
                            {workspace.has_subscription &&
                                <div className="text-xs">
                                    {toTime(balances.total)} remaining
                                </div>
                            }
                            {!workspace.has_subscription &&
                                <div className="text-xs">
                                    No subscription
                                </div>
                            }
                        </div>
                    }
                    {authed &&
                        <UserMenu/>
                    }
                </div>
            </div>
        </nav>
    );
}
