import React from "react";
import {Form, Link, Navigate, useActionData, useNavigation} from "react-router-dom";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import Head from "../components/Head";
import {useSelector} from "react-redux";
import Auth from "../classes/api/Auth";

export async function LoginPageAction({request}) {
    const data = await request.formData();
    try {
        await Auth.login(Object.fromEntries(data));
    } catch (error) {
        return {error: 'Your credentials are incorrect, please check them and try again.'};
    }
    return null;
}

export default function LoginPage() {
    const {user} = useSelector((state) => state.identity);
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';
    const [parent, enableAnimations] = useAutoAnimate()
    const actionData = useActionData() || {};
    const {error} = actionData;

    if (user !== null) {
        console.log('navigating');
        return <Navigate to="/" replace={true}/>
    }

    return (
        <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
            <Head title='Sign in'/>

            <div className="hidden w-full place-items-center lg:grid">
                <div className="w-full max-w-lg p-6">
                    <img className="w-full" src="/images/illustrations/dashboard-check.svg" alt="image"/>
                </div>
            </div>

            <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
                <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
                    <div className="text-center">
                        <div className="mx-auto" style={{maxWidth: 150}}>
                            <img style={{width: '100%'}} src="/images/logo-login.png" alt="logo"/>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                                Welcome Back
                            </h2>
                            <p className="text-slate-400 dark:text-navy-300">
                                Please sign in to continue
                            </p>
                        </div>
                    </div>
                    <div className="mt-16">
                        <Form method="post" action="/auth/signin" ref={parent}>
                            {error && !isSubmitting &&
                                <div
                                    className="alert flex mb-3 overflow-hidden rounded-lg bg-secondary/10 text-secondary dark:bg-secondary-light/15 dark:text-secondary-light"
                                >
                                    <div className="w-1.5 bg-secondary"></div>
                                    <div className="p-4">{error}</div>
                                </div>
                            }
                            <label className="relative flex">
                                <input
                                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                    placeholder="email" type="email" name="email" disabled={isSubmitting} required/>
                                <span
                                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200"
                         fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                    </svg>
                  </span>
                            </label>
                            <label className="relative mt-4 flex">
                                <input
                                    className="form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                                    placeholder="Password" type="password" name="password" disabled={isSubmitting}
                                    required/>
                                <span
                                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 transition-colors duration-200"
                         fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                    </svg>
                  </span>
                            </label>
                            <div className="mt-4 text-right space-x-2">
                                <Link to="/auth/reset"
                                      className="text-xs text-slate-400 transition-colors line-clamp-1 hover:text-slate-800 focus:text-slate-800 dark:text-navy-300 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                >
                                    Forgot Password?
                                </Link>
                            </div>

                            <button
                                disabled={isSubmitting}
                                className="btn mt-10 h-10 disabled:opacity-60 w-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                                {isSubmitting &&
                                    <div
                                        className="mr-2 spinner h-4 w-4 animate-spin rounded-full border-[3px] border-info/30 border-r-success"
                                    />
                                }

                                Sign In
                            </button>
                        </Form>
                        <div className="mt-4 text-center text-xs+">
                            <p className="line-clamp-1">
                                <span>Dont have Account? </span>
                                <Link to="/auth/register"
                                      className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                                    Create account
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
                    <a target="_blank" href="https://howto.iheartcaptions.cc/legal/terminos-y-condiciones">Terms of
                        service</a>
                </div>
            </main>
        </div>
    );
}
